<template>
    <div>
        <h5 class="gst-event-not-available__title">
            {{ $t( 'title' ) }}
        </h5>
        <div class="gst-event-not-available__content">
            <p>{{ $t( 'subtitle' ) }} <a :href="ticketsMasterUrl">ticketmaster.com</a></p>
        </div>
    </div>
</template>

<script>
    import tenantUrlsConstants from '@tenant/app/utils/constants/tenantUrls';

    export default {
        name: 'EventStateOfferExpired',
        i18nOptions: {
            namespaces: '_common',
            keyPrefix: 'messages.eventState.offerExpired'
        },
        computed: {
            ticketsMasterUrl( ) {
                return tenantUrlsConstants.home;
            }
        },
        created( ) {
            setTimeout( ( ) => {
                window.location.href = tenantUrlsConstants.home;
            }, 10000 );
        }
    };
</script>